.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-pay {
  box-sizing: border-box;
  position: relative;
  display: block;
  min-height: 38px;
  padding: 10px;
  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  font-family: Lato, Muli, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  word-break: break-word;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  text-decoration: none;
  background-color: rgb(75, 177, 121);
  cursor: pointer;
}

.modal-content  {
  -webkit-border-before-color: white !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important; 
}
.cancelBtn{
  /* background-color: red; */
  /* width: 10px; */
  /* height: 7px;
 */
  /* width: 20px;  */
  margin-left: 10px;
  margin-top: 5px;
  font-size: 10px;
}
.couponDiv{
  display: flex;
  width: fit-content;
  height: 45px;
  background: #FAFAFA;
  border: 2px solid #C4C4C4;
  border-radius: 9px;
}
.remove{
  margin-left: 13px;
  margin-right: 16px;
  color: #808080;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;

}